import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import cookie from "react-cookies";

// component
// import Homepage from "../components/Homepage/homepage";
const Homepage = dynamic(() => import("../components/Homepage/homepage"));
import service from "../Services/auth.api";

function Home() {
  useEffect(() => {
    service.authenticate().then((response) => {
      cookie.save("token", response.data.token);
      localStorage.setItem("token",response.data.token)
    });
  }, []);
  return (
    <div className="mx-auto">
      <main>
        <Homepage />
      </main>
    </div>
  );
}

export default Home;
